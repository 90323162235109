<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 30"
  >
    <path
      fill="currentColor"
      d="M28 6h-4V4c0-2-2-4-4-4h-8c-2 0-4 2-4 4v2H4c-2 0-4 2-4 4v16c0 2 2 4 4 4h24c2 0 4-2 4-4V10c0-2-2-4-4-4ZM11 4l1-1h8l1 1v2H11V4Zm12 5v18H9V9h14ZM3 26V10l1-1h2v18H4l-1-1Zm26 0-1 1h-2V9h2l1 1v16Z"
    />
  </svg>
</template>
